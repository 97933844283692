import { ClientEnvironment, ClientEnvironmentName } from '~/core/types/environment';

export const environment: ClientEnvironment = {
	environmentName: ClientEnvironmentName.TEST,

	server: {
		url: 'https://api.experimental.rea.ch',
	},

	mainClient: {
		url: 'https://mobile.experimental.rea.ch',
	},

	handbook: {
		url: 'https://handbook.dev.rea.ch',
	},

	myGenieServer: {
		url: 'https://chat.experimental.rea.ch',
	},
};
